var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default-layout ui fluid container" },
    [
      _c("t-header"),
      _c(
        "main",
        { staticClass: "main-page", attrs: { id: "main" } },
        [_c("router-view")],
        1
      ),
      _vm.alertMessage
        ? _c(
            "t-alert",
            {
              attrs: {
                type: _vm.alertType,
                icon: _vm.alertIcon,
                timer: 5000,
                progress: "",
                fixed: "",
                close: ""
              }
            },
            [_c("p", { domProps: { innerHTML: _vm._s(_vm.alertMessage) } })]
          )
        : _vm._e(),
      _c("t-footer"),
      _c("t-coming-soon", { attrs: { show: _vm.showComingSoon } }),
      _c("t-session-expired", { attrs: { show: !_vm.isLoggedIn } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }