import { isProduction } from '@/config'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'TDefaultLayout',
  components: {
    THeader: () => import('@/components/header'),
    TFooter: () => import('@/components/footer'),
    TComingSoon: () => import('@/components/coming-soon'),
    TSessionExpired: () => import('@/components/session-expired')
  },
  computed: {
    ...mapGetters({ user: 'getUser', isLoggedIn: 'isLoggedIn' }),
    ...mapState({
      alertType: ({ alert }) => alert.type,
      alertIcon: ({ alert }) => alert.icon,
      alertMessage: ({ alert }) => alert.message,
      showComingSoon: ({ comingSoon }) => comingSoon.show,
    })
  },
  methods: {

    renderChat () {
      (function (o, c, t, a, d, e, s, k) {
        o.octadesk = o.octadesk || {}
        s = c.getElementsByTagName('body')[0]
        k = c.createElement('script')
        k.async = 1
        k.src = t + '/' + a + '?showButton=' + d + '&openOnMessage=' + e
        s.appendChild(k)
      })(window, document, 'https://chat.octadesk.services/api/widget', 'trackmob', true, true)
    },

    loginChat (o) {
      const name = this.user.name
      const email = this.user.email

      if (o && Object.keys(o).length > 0) {
        o.chat.login({ name, email })
      }
    },

    freshDesk() {
      window.fwSettings={
        'widget_id':60000003011
      };
      // eslint-disable-next-line
      !function(){
        if(typeof window.FreshworksWidget!=="function"){
          var n = function(){
            n.q.push(arguments)
          };

          // eslint-disable-next-line
          n.q=[],window.FreshworksWidget=n
        }
      }()

      let freshDeskWidget = document.createElement('script')
      freshDeskWidget.setAttribute('src', 'https://widget.freshworks.com/widgets/60000003011.js')
      freshDeskWidget.setAttribute('async', true)
      freshDeskWidget.setAttribute('defer', true)
      document.body.appendChild(freshDeskWidget)
    }
  },
  mounted () {
    if (isProduction) {
      this.freshDesk()
    }
  }
}
